import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands,light } from '@fortawesome/fontawesome-svg-core/import.macro';


function SkipButton(props) { 
	let funcid;
	let skipAnim;
	if (props.skipText) { 
		let circleClass='';
	} else { 
		let circleClass='button-circle';
	}
	if (props.goto) { 
		funcid=props.goto;
	} else { 
		funcid=4;
	}
	if (props.skipAnim) { 
		  skipAnim='skipAnim';
	} else { 
		  skipAnim='';
	}
	return (
<button className={`footer-right-button button-right button go-func ${skipAnim}  ${props.circleClass}`}
 data-func={funcid}>
<FontAwesomeIcon icon={regular('forward-step')} />
		 <span>{props.skipText}</span>
	</button>)
}
export default SkipButton


