import React from "react";
import BigText from "./BigText";
import Footer from "./Footer";
import TopText from "./TopText";
import { useRef, useEffect, useState } from "react";
import $ from "jquery";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import Video from "../assets/layout/video_1_google.mp4";
import useSound from "use-sound";
import mySound from "../assets/music.mp3"; // Your sound file path here

const Slide1 = ({ childFunc }) => {
  const [play, { stop }] = useSound(mySound, { volume: 0.4, loop: true });
  childFunc.play = play;
  childFunc.stop = stop;
  const myvideo = useRef(null);
  const [mystream, setmystream] = useState(null);
  const [videoswitch, setvideo] = useState(true);

  const vidRef = useRef(null);

  const handlePlayVideo = () => {
    vidRef.current.currentTime = 0;
    $(".video-dialog-buttons").removeClass("active");
    $("#slide1 .skipAnim").removeClass("d-none");
    vidRef.current.play();
  };
  const handleEndVideo = () => {
    vidRef.current.pause();
    vidRef.current.currentTime = vidRef.current.duration - 0.1;
    $(".timer").html("00:00");
  };
  const stopPlayVideo = () => {
    vidRef.current.pause();
  };

  useEffect(() => {
    childFunc.handlePlayVideo = handlePlayVideo;
    childFunc.handleEndVideo = handleEndVideo;
    childFunc.stopPlayVideo = stopPlayVideo;
  }, []);

  return (
    <div className="slide" id="slide1" data-id="1">
      <div className="video-wrap">
        <div className="timer"></div>
        <video playsInline id="video1" ref={vidRef}>
          <source src={Video} type="video/mp4" />
        </video>
      </div>
      <Footer videoDialog skipButton skipText="Skip" skipAnim="1" goto="999" />
    </div>
  );
};
export default Slide1;
