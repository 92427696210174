import React from "react";
import { useRef, useEffect, useState } from "react";
import ReactGA from "react-ga";

import BigText from "./BigText";
import BackButton from "./BackButton";
import SkipButton from "./SkipButton";
import Footer from "./Footer";
import TopText from "./TopText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import $ from "jquery";
import * as tf from "@tensorflow/tfjs";

import Webcam from "react-webcam";
// import * as mobilenet from "@tensorflow-models/mobilenet";

let STATUS = document.getElementById("result-number");
const VIDEO = document.getElementById("webcam");
const ENABLE_CAM_BUTTON = document.getElementById("enableCam");
const RESET_BUTTON = document.getElementById("reset");
const TRAIN_BUTTON = document.getElementById("train");
const MOBILE_NET_INPUT_WIDTH = 224;
const MOBILE_NET_INPUT_HEIGHT = 224;
const STOP_DATA_GATHER = -1;
const CLASS_NAMES = [];
let wasClass = "";
let classNumber = -1;
let video;
// ENABLE_CAM_BUTTON.addEventListener('click', enableCam);
// TRAIN_BUTTON.addEventListener('click', trainAndPredict);
// RESET_BUTTON.addEventListener('click', reset);
let prediction;
let model;
let mobilenet = undefined;
let gatherDataState = STOP_DATA_GATHER;
let videoPlaying = false;
let trainingDataInputs = [];
let trainingDataOutputs = [];
let examplesCount = [];
let predict = false;
let videoFrameAsTensor;
let resizedTensorFrame;
let imageFeatures;
let answer;
let ka;
async function loadMobileNetFeatureModel() {
  console.log("wczytywanie");
  if (model) {
    console.log(model);
    tf.dispose(model);
    console.log(model);
    tf.dispose("*");

    tf.dispose();
    tf.engine().disposeTensor(videoFrameAsTensor);
    tf.engine().startScope();
  }

  console.log(tf.memory());

  const URL =
    "https://tfhub.dev/google/tfjs-model/imagenet/mobilenet_v3_small_100_224/feature_vector/5/default/1";
  if (mobilenet) {
    tf.dispose(mobilenet);
    mobilenet = "";
  }
  mobilenet = await tf.loadGraphModel(URL, { fromTFHub: true });
  // STATUS.innerText = 'MobileNet v3 loaded successfully!';

  // Warm up the model by passing zeros through it once.
  tf.tidy(function () {
    answer = mobilenet.predict(
      tf.zeros([1, MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH, 3])
    );

    model = tf.sequential();
    model.add(
      tf.layers.dense({ inputShape: [1024], units: 128, activation: "relu" })
    );
    model.add(tf.layers.dense({ units: 2, activation: "softmax" }));

    model.summary();

    // Compile the model with the defined optimizer and specify a loss function to use.
    model.compile({
      // Adam changes the learning rate over time which is useful.
      optimizer: "adam",
      // Use the correct loss function. If 2 classes of data, must use binaryCrossentropy.
      // Else categoricalCrossentropy is used if more than 2 classes.
      loss:
        CLASS_NAMES.length === 2
          ? "binaryCrossentropy"
          : "categoricalCrossentropy",
      // As this is a classification problem you can record accuracy in the logs too!
      metrics: ["accuracy"],
    });
  });
}

// Call the function immediately to start loading.

function hasGetUserMedia() {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}

function enableCam() {
  if (hasGetUserMedia()) {
    // getUsermedia parameters.
    const constraints = {
      video: true,
      width: "100%",
      height: $(window).height() * 0.48,
    };

    // Activate the webcam stream.
    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
      //  VIDEO.addEventListener('loadeddata', function() {
      // videoPlaying = true;
      // ENABLE_CAM_BUTTON.classList.add('removed');
      // });
    });
  } else {
    console.warn("getUserMedia() is not supported by your browser");
  }
}

/**
 * Handle Data Gather for button mouseup/mousedown.
 **/

async function trainAndPredict() {
  console.log("  Tensors after:", tf.memory().numTensors);

  predict = false;
  tf.util.shuffleCombo(trainingDataInputs, trainingDataOutputs);
  let outputsAsTensor = tf.tensor1d(trainingDataOutputs, "int32");
  let oneHotOutputs = tf.oneHot(outputsAsTensor, CLASS_NAMES.length);
  let inputsAsTensor = tf.stack(trainingDataInputs);
  let results = await model.fit(inputsAsTensor, oneHotOutputs, {
    shuffle: true,
    batchSize: 5,
    epochs: 10,
    callbacks: { onEpochEnd: logProgress },
  });

  tf.dispose(outputsAsTensor);
  tf.dispose(oneHotOutputs);
  tf.dispose(inputsAsTensor);

  predict = true;
  $(".train2, .waitOver1").addClass("d-none");

  $(".waitOver1").removeClass("d-none");
  setTimeout(function () {
    ReactGA.event({
      category: "TM_Experiment",
      action: "TM_Training_end",
    });
    ReactGA.event({
      category: "TM_Experiment",
      action: "Teachable_Machine_Finished",
    });
    $(".train3").removeClass("d-none");
    $(".fourth-text").removeClass("inactive");
    $(".waitOver1").addClass("d-none");
  }, 6000);

  predictLoop();
}

function logProgress(epoch, logs) {
  $(".progress-val").html(epoch * 10 + 15 + "%");
  $(".progress-in").css(
    "transform",
    "translateX(" + (-100 + (epoch * 10 + 15)) + "%) scale(1.05)"
  );
  console.log("Data for epoch " + epoch, logs);
}

function predictLoop() {
  if (predict) {
    tf.tidy(function () {
      videoFrameAsTensor = tf.browser.fromPixels(video).div(255);
      resizedTensorFrame = tf.image.resizeBilinear(
        videoFrameAsTensor,
        [MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH],
        true
      );

      imageFeatures = mobilenet.predict(resizedTensorFrame.expandDims());
      prediction = model.predict(imageFeatures).squeeze();
      let highestIndex = prediction.argMax().arraySync();
      let predictionArray = prediction.arraySync();
      let leftresult = (predictionArray[0] * 100) / 2;
      let rightresult = (predictionArray[1] * 100) / 2;
      if (parseInt(leftresult * 2) == 99) {
        leftresult = 50;
      }
      if (parseInt(rightresult * 2) == 99) {
        rightresult = 50;
      }
      if (leftresult > 25) {
        $("#result0").addClass("winner").removeClass("loser");
        $("#result1").addClass("loser").removeClass("winner");
      } else if (rightresult > 25) {
        $("#result1").addClass("winner").removeClass("loser");
        $("#result0").addClass("loser").removeClass("winner");
      } else {
        $("#result0, #result1").removeClass("winner loser");
      }
      $("#result0 .resulto-prog").css("width", leftresult * 2 + "%");
      $("#result0")
        .find("span")
        .html(parseInt(leftresult * 2) + "%");
      $("#result1 .resulto-prog").css("width", rightresult * 2 + "%");
      $("#result1")
        .find("span")
        .html(parseInt(rightresult * 2) + "%");
      //
      let STATUS = document.getElementById("result-number");
      // document.getElementById('result-number').innerHTML = 'Prediction: ' + CLASS_NAMES[highestIndex] + ' with ' + Math.floor(predictionArray[highestIndex] * 100) + '% confidence';
    });

    window.requestAnimationFrame(predictLoop);
  }
}

/**
 * Purge data and start over. Note this does not dispose of the loaded
 * MobileNet model and MLP head tensors as you will need to reuse
 * them to train a new model.
 **/

function onTrackedVideoFrame(currentTime, duration) {
  let ile = duration - currentTime;
  // $(".timer").text(secondsToHms(ile));
}
function secondsToHms(secs) {
  secs = Math.round(secs);
  var hours = Math.floor(secs / (60 * 60));

  var divisor_for_minutes = secs % (60 * 60);
  var minutes = Math.floor(divisor_for_minutes / 60);

  var divisor_for_seconds = divisor_for_minutes % 60;
  var seconds = Math.ceil(divisor_for_seconds);

  var obj = {
    h: hours,
    m: minutes,
    s: seconds,
  };
  return minutes + ":" + seconds;
}

let hrs, mins, secs;

$(document).ready(function () {
  enableCam();
});
const Slide3 = ({ childFunc }) => {
  console.log("slide3");
  const webcamRef = React.useRef(null);
  const resetFun = () => {
    const CLASS_NAMES = [];
    console.log("reset");
    loadMobileNetFeatureModel();
    tf.disposeVariables();
    predict = false;
    examplesCount = [];
    examplesCount.length = 0;

    classNumber = -1;
    gatherDataState = STOP_DATA_GATHER;
    videoFrameAsTensor = "";
    resizedTensorFrame = "";
    imageFeatures = "";
    for (let i = 0; i < trainingDataInputs.length; i++) {
      trainingDataInputs[i].dispose();
      tf.dispose(trainingDataInputs[i]);
    }
    for (let i = 0; i < trainingDataOutputs.length; i++) {
      tf.dispose(trainingDataOutputs[i]);
    }

    trainingDataInputs = [];
    trainingDataOutputs = [];

    examplesCount = [];
    predict = false;

    trainingDataInputs.length = 0;
    trainingDataOutputs.length = 0;

    console.log("Tensors in memory: " + tf.memory().numTensors);
    console.log(tf.memory());

    $(".waitOver0").removeClass("d-none");

    $(".train1,.dataCollector0").removeClass("d-none inactive");
    $(".train2,.dataCollector1").addClass("d-none inactive");
    $(".second-text, .third-text, .fourth-text").addClass("inactive");
    $(".train3,#result, .waitOver, .waitOver1").addClass("d-none");
    $("#webcam-wrap").removeClass("d-none");
    $("#pics").empty();
    $(".progress-val").html("15%");
    $(".progress-in").css("transform", "translateX(-85%)");
    setTimeout(function () {
      $(".waitOver0").addClass("d-none");
    }, 6000);

    // console.log('Tensors in memory: ' + tf.memory().numTensors);
  };
  useEffect(() => {
    childFunc.reseto = resetFun;
    video = webcamRef.current;
    let STATUS = document.getElementById("result-number");
    const constraints = { video: true };
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      video.srcObject = stream;
      videoPlaying = true;
    });

    let dataCollectorButtons = document.querySelectorAll(
      "button.dataCollector"
    );
    CLASS_NAMES.splice(0, 2);

    for (let i = 0; i < dataCollectorButtons.length; i++) {
      //   dataCollectorButtons[i].addEventListener('mousedown', gatherDataForClass);
      // dataCollectorButtons[i].addEventListener('mouseup', gatherDataForClass);
      // Populate the human readable names for classes.
      CLASS_NAMES.push(dataCollectorButtons[i].getAttribute("data-name"));
    }
  });

  const gatherDataForClass = function (event) {
    classNumber = parseInt(event.target.getAttribute("data-1hot"));
    // if (!wasClass) {
    // 	let classNumber=0;
    // } else {
    // 	let classNumber=1;
    // }
    gatherDataState =
      gatherDataState === STOP_DATA_GATHER ? classNumber : STOP_DATA_GATHER;
    console.log(event.type);
    if (event.type == "click" || event.type == "tap") {
      $("#pics, .dataCollector").addClass("animuj");
      $("#video-over").addClass("animuj");
      ReactGA.event({
        category: "TM_Experiment",
        action: "TM_Gather_data" + classNumber,
      });

      dataGatherLoop();
    } else {
      $("#pics, .dataCollector").removeClass("animuj");
      $("#video-over").removeClass("animuj");
      gatherDataState = STOP_DATA_GATHER;
    }
  };
  function dataGatherLoop() {
    if (videoPlaying && gatherDataState !== STOP_DATA_GATHER) {
      imageFeatures = tf.tidy(function () {
        videoFrameAsTensor = tf.browser.fromPixels(video);
        resizedTensorFrame = tf.image.resizeBilinear(
          videoFrameAsTensor,
          [MOBILE_NET_INPUT_HEIGHT, MOBILE_NET_INPUT_WIDTH],
          true
        );
        let normalizedTensorFrame = resizedTensorFrame.div(255);
        return mobilenet.predict(normalizedTensorFrame.expandDims()).squeeze();
      });
      let STATUS = document.getElementById("result-number");
      let pics = document.querySelector("#pics");
      let canvas = document.createElement("canvas");
      let div = document.getElementById("pics");
      div.innerHTML = "";
      canvas.id = "CursorLayer";
      canvas.width = 640;
      canvas.height = 480;
      canvas.style.width = "100%";
      canvas.style.zIndex = 8;
      canvas.style.position = "relative";
      canvas.style.border = "1px solid";
      div.appendChild(canvas);

      if (examplesCount[gatherDataState] == 100 && classNumber * 1 !== 1) {
        let div1 = document.getElementById("resulto-img0");
        div1.innerHTML = "";

        div1.appendChild(canvas);
      }
      if (examplesCount[gatherDataState] == 100 && classNumber * 1 === 1) {
        let div2 = document.getElementById("resulto-img1");
        div2.innerHTML = "";
        div2.appendChild(canvas);
      }
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);
      trainingDataInputs.push(imageFeatures);
      trainingDataOutputs.push(gatherDataState);

      // Intialize array index element if currently undefined.
      if (examplesCount[gatherDataState] === undefined) {
        examplesCount[gatherDataState] = 0;
      }
      examplesCount[gatherDataState]++;

      STATUS.innerText = "";

      STATUS.innerText = examplesCount[gatherDataState] + "";
      let sekund = parseInt(216 - examplesCount[gatherDataState]);
      $(".seconds" + classNumber).html(sekund);
      console.log(classNumber);
      console.log(examplesCount[gatherDataState]);
      $("#result").removeClass("d-none");
      if (examplesCount[gatherDataState] > 215 && classNumber * 1 !== 1) {
        let wasClass = 0;
        gatherDataState = STOP_DATA_GATHER;
        $("#pics, .dataCollector").removeClass("animuj");
        $("#video-over").removeClass("animuj");
        $(".dataCollector0").addClass("inactive");

        setTimeout(function () {
          $(".waitOver").removeClass("d-none");
          $("#result-number").html(0);
          $("#pics").empty();
          $("#result").addClass("d-none");
        }, 500);
        setTimeout(function () {
          $(".waitOver").addClass("d-none");
          $(".dataCollector1").removeClass("d-none");
          $(".dataCollector1").removeClass("inactive");
          $(".dataCollector0").addClass("d-none");
          $(".first-text").addClass("inactive");
          $(".second-text").removeClass("inactive");
        }, 5500);

        return;
      } else if (examplesCount[gatherDataState] > 215 && classNumber * 1 == 1) {
        let wasClass = 1;
        $("#pics").removeClass("animuj");
        $("#video-over, .dataCollector").removeClass("animuj");
        gatherDataState = STOP_DATA_GATHER;
        $(".dataCollector1").addClass("inactive");
        $(".dataCollector1").addClass("inactive");
        $(".train1").addClass("d-none");
        $(".train2").removeClass("d-none");
        $(".second-text").addClass("inactive");
        $("#result").addClass("d-none");
        $("#pics").empty();
        $(".progress-val").html(5 + "%");
        $(".progress-in").css(
          "transform",
          "translateX(" + (-100 + 5) + "%) scale(1.05)"
        );

        setTimeout(function () {
          ReactGA.event({
            category: "TM_Experiment",
            action: "TM_Training_start",
          });
          trainAndPredict();
        }, 40);
        return;
      }
      window.requestAnimationFrame(dataGatherLoop);
    }
  }

  // componentDidMount() {
  // 	const video = this.videoRef.current;
  // 	const constraints = { video: true }
  // 	navigator.mediaDevices.getUserMedia(constraints).then(
  // 	  (stream) => { video.srcObject = stream })
  //   }
  return (
    <div className="slide" id="slide3" data-id="3">
      <div className="gather-wrap">
        <div className="train1 first-text">
          1. Place the first object in front of the camera.
          <br />
          2. Tap the record button.
          <br />
          3. Show the object from all angles.
        </div>
        <div className="second-text inactive">
          1. Place the second object in front of the camera.
          <br />
          2. Tap the record button.
          <br />
          3. Show the object from all angles.
        </div>
        <div className="fourth-text inactive">
          Compare each object in front of the camera to check how computer
          trained the model.
        </div>
        <TopText
          addClass="train3 third-text inactive"
          text="Check that the computer has trained the objects correctly."
        />
        <div id="webcam-wrap" className="train1 train3">
          <div id="pics"></div>
          <div id="result" className="d-none">
            <span id="result-number"></span>/216
          </div>
          <div id="video-over"></div>
          <video id="webcam" muted autoPlay={true} ref={webcamRef}></video>

          <div className="train3 train-results d-none">
            <div id="result0" className="resulto">
              <span></span>
              <div className="resulto-desc">
                Object 1 <div className="resulto-img" id="resulto-img0"></div>
              </div>
              <div className="resulto-prog"></div>
            </div>
            <div id="result1" className="resulto">
              <span></span>
              <div className="resulto-desc">
                Object 2 <div className="resulto-img" id="resulto-img1"></div>
              </div>
              <div className="resulto-prog"></div>
            </div>
          </div>
        </div>

        <div className="footer train1" id="enableCam">
          <BackButton />
          <button
            onClick={gatherDataForClass}
            className="footer-big-button button-center dataCollector dataCollector0 button"
            data-1hot="0"
            data-name="Class 1"
          >
            <FontAwesomeIcon icon={regular("circle-video")} />{" "}
            <span className="record-text">Record</span>
            <span className="seconds-text seconds0">216</span>
            <span className="seconds-desc">
              FRAMES
              <br />
              REMAINING
            </span>
          </button>
          <button
            onClick={gatherDataForClass}
            className="footer-big-button button-center d-none dataCollector dataCollector1 inactive button"
            data-1hot="1"
            data-name="Class 2"
          >
            <FontAwesomeIcon icon={regular("circle-video")} />{" "}
            <span className="record-text">Record</span>
            <span className="seconds-text seconds1">216</span>
            <span className="seconds-desc">
              FRAMES
              <br />
              REMAINING
            </span>
          </button>
          <SkipButton skipText="Skip" />
        </div>
      </div>

      <div className="wait d-none train2">
        <div className="big-text">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-md-6 text-center">
              TRAINING OF COLLECTED DATA
              <br />
              <br />
              <div className="progress-wrap">
                <div className="progress-wrap-in">
                  <div className="progress-in"></div>
                </div>
              </div>
              <br />
              <br />
              <span className="progress-val"></span> / 100%
            </div>
          </div>
        </div>
      </div>
      <div className="train3 d-none w-100">
        <Footer backButton skipButton padding skipText="Finish" />
      </div>
      <div className="waitOver0">
        Choose one object from the desk below and continue to collect data.
      </div>

      <div className="waitOver d-none">
        Now take the second object and train another set of data.
      </div>
      <div className="waitOver1 d-none">
        Check if the computer trained the model correctly.
      </div>
    </div>
  );
};
export default Slide3;
