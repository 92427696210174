import React from "react";
import { useRef, useEffect } from "react";
import ReactGA from "react-ga";
import useAnalyticsEventTracker from "./useAnalyticsEventTracker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import $ from "jquery";
import Slide0 from "./Slide0";
import Slide1 from "./Slide1";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";
import Slide4 from "./Slide4";
import { Action } from "history";

let slide = [];
function SlideContainer() {
  const childFunc = React.useRef(null);
  const gaEventTracker = useAnalyticsEventTracker;

  useEffect(() => {
    ReactGA.pageview("/start");
  }, []);

  let timeout = null;

  function timeoutf() {
    if (timeout != null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(function () {
      slide[0]();
    }, 240000);

    $(document).one("tap click", function () {
      clearTimeout(timeout);
      timeoutf();
    });
  }

  function onTrackedVideoFrame(currentTime, duration) {
    let ile = duration - currentTime;
    $(".slide.active .timer").text(secondsToHms(ile));
  }
  function secondsToHms(secs) {
    secs = Math.round(secs);
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    if (minutes * 1 < 10) {
      minutes = "0" + minutes;
    }
    var divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    var obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return minutes + ":" + seconds;
  }

  let hrs, mins, secs;
  slide[0] = function () {
    // ReactGA.event({ category: "TM_Start", action: "TM_Enter_page" });
    changeSlide("3", "0");
    childFunc.stop();
    childFunc.stopPlayVideo();
    childFunc.stopPlayVideo4();
  };
  function changeSlide(s1, s2) {
    const aktywny = $(".slide.active");

    $(".slide.active").removeClass("active");
    $("#slide" + s2).addClass("pre-active");
    setTimeout(function () {
      $("#slide" + s2).addClass("active");
    }, 50);
    setTimeout(function () {
      $(aktywny).removeClass("pre-active");
    }, 550);
  }

  slide[1] = function () {
    ReactGA.event({
      category: "TM_First video",
      action: "Teachable_Machine_Start",
    });

    // ReactGA.pageview("/Video");
    childFunc.play();
    timeoutf();

    changeSlide("0", 1);
    childFunc.handlePlayVideo();
    let but = $("#slide1 .button-right");
    $(but).removeClass("button-circle");
    $("#slide1 .video-wrap").removeClass("active");
    let szer = but.outerWidth();
    // but.css({'width':szer});
    setTimeout(function () {
      $("#slide1 .video-wrap").addClass("active");
    }, 500);
    // setTimeout(function() {
    //  $(but).addClass('button-circle');
    // },500);
    $("#slide1 .video-wrap video").on("timeupdate", function (event) {
      onTrackedVideoFrame(this.currentTime, this.duration);
    });
    $("#slide1 .video-wrap video").one("ended", function (event) {
      $(this).off("timeupdate");
      childFunc.handleEndVideo();

      $(".video-dialog-buttons").addClass("active");
      $("#slide1 .skipAnim").addClass("d-none");
    });
  };
  slide[999] = function () {
    // $("#slide1 .video-wrap video").off('timeupdate');
    // childFunc.handleEndVideo();

    $(".video-dialog-buttons").addClass("active");
    $("#slide1 .skipAnim").addClass("d-none");
  };
  slide[9999] = function () {
    // $(".slide.active").removeClass("active");
    // setTimeout(function () {
    //   window.location.reload();
    // }, 500);
    // ReactGA.event({ category: "Skip", action: "Clicked" });

    slide[0]();
  };
  slide[2] = function () {
    changeSlide("1", 2);
    // ReactGA.pageview("/tutorial");
    // ReactGA.event({ category: "First video", action: "Leave" });
    ReactGA.event({ category: "TM_Tutorial", action: "TM_Enter_tutorial" });

    childFunc.stopPlayVideo();
  };
  slide[3] = function () {
    changeSlide("2", 3);
    childFunc.reseto();
    // ReactGA.pageview("teachable");
    // ReactGA.event({ category: "Tutorial", action: "Leave" });
    ReactGA.event({ category: "TM_Experiment", action: "TM_Enter_experiment" });
  };
  slide[4] = function () {
    // gaEventTracker("Koniec");
    // ReactGA.event({ category: "Experiment", action: "Leave" });
    ReactGA.event({
      category: "TM_Second video",
      action: "TM_Enter_second_video",
    });

    changeSlide("3", 4);
    childFunc.handlePlayVideo4();
    $("#slide4 .video-wrap video").one("ended", function (event) {
      // ReactGA.event({ category: "Skip", action: "Clicked" });

      slide[9999]();
    });
  };
  useEffect(() => {
    $(".go-func").on("click", function () {
      let func = $(this).attr("data-func");
      slide[func]();
    });
  });
  return (
    <div>
      <Slide0 />
      <Slide1 childFunc={childFunc} />
      <Slide2 />
      <Slide3 childFunc={childFunc} />
      <Slide4 childFunc={childFunc} />
    </div>
  );
}
export default SlideContainer;
