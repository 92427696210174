import "./App.css";
import ReactGA from "react-ga";

import SlideContainer from "./components/SlideContainer";
const TRACKING_ID = "UA-258619762-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
function App() {
  return <SlideContainer />;
}

export default App;
