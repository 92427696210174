import React from "react"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands,light } from '@fortawesome/fontawesome-svg-core/import.macro';


function ExtraButton(props) { 
	let iconName='play';
	let icon=<FontAwesomeIcon icon={regular('angle-right')} />
	
		return (
		<button className={props.extraButtonClass} data-func={props.extraButtonFunc}>
		 {icon}{props.extraButtonText}
		 </button>
		 )
}
export default ExtraButton


