import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";

function BackButton(props) {
  return (
    <button
      className="footer-left-button button go-func button-left button-circle"
      data-func="2"
    >
      <FontAwesomeIcon icon={regular("arrow-turn-down-left")} />
      <span>Back</span>
    </button>
  );
}
export default BackButton;
