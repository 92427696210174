import React, { useLayoutEffect } from "react";
import BigText from "./BigText";
import Footer from "./Footer";
import TopText from "./TopText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import $ from "jquery";

function Slide0() {
  useLayoutEffect(() => {
    setTimeout(function () {
      $("#slide0").addClass("active");
    }, 500);
  });
  return (
    <div className="slide  pre-active" id="slide0" data-id="0">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-md-6 cytat">
          "Google Image Search can improve conversion rates up
          <br />
          to four times compared to traditional keyword searches."
          <span>— Katarzyna Dorsey, CEO Yosh.AI</span>
        </div>
        <Footer
          extraButton
          extraButtonClass="go-func big-button-center"
          extraButtonFunc="1"
          extraButtonText="Tap to start"
        />
      </div>
    </div>
  );
}
export default Slide0;
