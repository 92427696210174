import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, light } from '@fortawesome/fontawesome-svg-core/import.macro';
function VideoDialogButtons(props) {
	return (
		<div className="video-dialog-buttons">
			<div className="video-dialog">Wow, that's amazing! Do you want to see how the AI model works on the example of the Teachable Machine from Google?</div>

			<button className="footer-small-button go-func" data-func="0">
				<FontAwesomeIcon icon={regular('times')} /> No
			</button>
			<button className="footer-small-button  go-func" data-func="2">
				<FontAwesomeIcon icon={regular('check')} /> Yes
			</button>
		</div>
	);
}
export default VideoDialogButtons;
