import React from "react";
import BigText from "./BigText";
import Footer from "./Footer";
import TopText from "./TopText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import Tutorial from "../assets/layout/tutorial/tutorial.png";
import TutorialImg1 from "../assets/layout/tutorial/tuto_1.svg";
import TutorialImg2 from "../assets/layout/tutorial/tuto_2.svg";
import TutorialImg3 from "../assets/layout/tutorial/tuto_3.svg";
import TutorialImg4 from "../assets/layout/tutorial/tuto_4.svg";

function Slide2(props) {
  let icona = "play";
  let extraButtonText = "Ok, got it";
  return (
    <div className="slide" id="slide2" data-id="2">
      <div className="row h-100 justify-content-center align-items-center">
        <div className="col-md-6">
          <div className="tuto">
            <img src={TutorialImg1} />
          </div>
          <div className="tuto">
            <img src={TutorialImg2} />
          </div>
          <div className="tuto">
            <img src={TutorialImg3} />
          </div>
          <div className="tuto">
            <img src={TutorialImg4} />
          </div>
        </div>
      </div>

      <Footer
        extraButton
        extraButtonClass="go-func big-button-center"
        extraButtonFunc="3"
        extraButtonText={extraButtonText}
        extraButtonIcon={icona}
      />
    </div>
  );
}
export default Slide2;
