import React from "react";
function BigText() { 
return(
	<div className='big-text'>
		<div className='row justify-content-center align-items-center h-100'>
			<div className='col-md-6 cytat'>
				“Even with an enormous 700% surge of activity on Booksy, we have managed to improve our app stability and quality of service delivery while the infrastructure
				was still able to cope.”
	<span>Paweł Sobkowiak, CTO, Booksy</span>
				
			</div>
		
	
				
			</div>
	
	</div>	
	
)


}
export default BigText