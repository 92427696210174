import React from "react"
import VideoDialogButtons from "./VideoDialogButtons";
import BackButton from "./BackButton";
import ExtraButton from "./ExtraButton";
import SkipButton from "./SkipButton";
let backButton,videoDialog,extraButton,skipButton,padding;

function Footer(props) { 
	if (props.padding) { 
		  padding=<span style={{padding: '2vw', display:'inline-block'}}></span>;
	} else { 
		  padding='';
	}
	if (props.backButton) { 
    backButton=<BackButton/>;
	} else { 
	backButton='';
	}
	if (props.videoDialog) { 
	videoDialog=<VideoDialogButtons/>;
	} else { 
	videoDialog='';
	}
	if (props.extraButton) { 
	extraButton=<ExtraButton extraButtonClass={props.extraButtonClass} extraButtonFunc={props.extraButtonFunc} extraButtonText={props.extraButtonText} extraButtonIcon={props.extraButtonIcon} />;
	} else { 
	extraButton='';
	}
	if (props.skipButton) { 
	skipButton=<SkipButton circleClass={props.circleClass} skipAnim={props.skipAnim} skipText={props.skipText} goto={props.goto}/>;
	} else { 
	skipButton='';
	}
	
return(
	 <div className='footer'>
	 {backButton}{padding}{videoDialog}{extraButton}{padding}{skipButton}
	 
	 </div>
 )


}
export default Footer;